<header>
      <nav class="navbar spa-main-nav navbar-expand-md">
         <!-- Brand -->
         <a class="navbar-brand spa-navbar-brand-img" [routerLink]="['/']">
              <img src="assets/images/insgeek.png">
         </a>
      </nav> 
    </header>

<div class="page">
    <div class="container-fluid">
       <div class="ryhq4q0_e296pg">
          <div class="row">
             <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-sm-12 m-auto wow fadeInRight" data-wow-delay="0.2s">
                <div class="we_compare" id="compare">
                   <h2>Our Misson</h2>
                   <p>We’re Insurance Geek, we aspire to help consumers make intelligent decisions when choosing their insurance. Smart consumers get more, pay less and save time.</p>
                   <p>Your personal Insurance Geek will help you shop & compare the best insurance carriers in America. You don’t have to be an expert when you have an Insurance Geek on your side.</p>
                   <h4 class="about-titles"><strong>30+ Carriers</strong></h4>
                   <p>Instantly shop over 30 of the countries top insurance companies.</p>
                   <h4 class="about-titles"><strong>100's of products</strong></h4>
                   <p>See results for 100’s of the top products offered by every company.</p>
                   <h4 class="about-titles"><strong>One Geek</strong></h4>
                   <p>Work with one of the smartest insurance agents you will ever come across.</p>
                </div>
             </div>
          </div>
       </div>
    </div>
</div>

<div class="get-quote">
   <button type="button" [routerLink]="['/get-quote']" class="btn get-quote-btn">Get Quote</button>
</div>