import { Component, OnInit } from '@angular/core';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { LeadFormModel } from '../../models/LeadForm';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteService } from 'src/app/services/quote.service';
import { UTM } from 'src/app/models/Utm.model';
import { UtmService } from 'src/app/services/utm.service';
import { SharedService } from 'src/app/services/shared.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConditionalModalComponent } from 'src/app/layouts/modal/conditional-modal/conditional-modal.component';

@Component({
  selector: 'app-spa-form',
  templateUrl: './spa-form.component.html',
  styleUrls: ['./spa-form.component.css']
})
export class SpaFormComponent implements OnInit {
  
  public hidden_termLife: boolean = false;
  public hidden_wholeUniversal: boolean = false;
  
  
  constructor(
    private animateScrollService: NgAnimateScrollService,
    public FormValues: LeadFormModel = new LeadFormModel,
    private router: Router,
    private _QuoteService: QuoteService,
    private _UtmService: UtmService,
    public activateRoute: ActivatedRoute,
    public shared: SharedService,
    public modalService: BsModalService,

  ) {  
      this.QueryParams = this.activateRoute.snapshot.queryParams;
  }

  public selected: boolean = true;
  public FormValid: boolean = false;
  public FormErrors: any = null;
  public Step: number = 0;
  public UtmData: UTM = null;
  public QueryParams: any = null;
  public isLoading: boolean = false;
  public isLoading2: boolean = false;
  public modal: BsModalRef;


  ngOnInit() {
    window.scroll(0,0);
    if(this.FormValues.Dob == null){
      this.scrollToDob();

    }
    this.checkDob();

  }

  ngAfterViewInit() {
    this.shared.communicator.subscribe(
      (res) => {
        if (res == 1) {
          this.submitForm();
        }
      }
    )
  }

  openModal() {
    this.modal = this.modalService.show(
      ConditionalModalComponent, 
      Object.assign({}, { class: 'custom-modal modal-dialog-centered modal-lg no-border' })
    );
  }

   
  scrollToDob(duration?:number) {
    this.animateScrollService.scrollToElement('scrollToDob', duration);
    this.Step = 0;
  }


  scrollToTop(duration?:number) {
    this.animateScrollService.scrollToElement('scrollToTop', duration);
    this.Step = 1;
  }

  scrollToCarrier(duration?:number) {
    this.animateScrollService.scrollToElement('scrollToCarrier', duration);
    this.Step = 2;
  }

 
  // scrollToIncome(duration?:number) {
  //   this.animateScrollService.scrollToElement('scrollToIncome', duration);
  //   this.Step = 2;
  // }

  // scrollToFaceAmount(duration?:number){
  //   this.animateScrollService.scrollToElement('scrollToFaceAmount', duration);
  //   this.Step = 3;
  // }

  scrollToPolicyType(duration?:number){
    this.animateScrollService.scrollToElement('scrollToPolicyType', duration);
    this.Step = 3;
  }

  scrollToHiddenPolicyQuestion(duration?:number){
    this.animateScrollService.scrollToElement('scrollToHiddenPolicyQuestion', duration);
    this.Step = 4;
  }

  scrollToDeathBenfits(duration?:number){
    this.animateScrollService.scrollToElement('scrollToDeathBenfits', duration);
    this.Step = 5;
  }

  // scrollToOccupation(duration?:number){
  //   this.animateScrollService.scrollToElement('scrollToOccupation', duration);
  //   this.Step = 7;
  // }

  // scrollToAgeToTakeIncome(duration?:number){
  //   this.animateScrollService.scrollToElement('scrollToAgeToTakeIncome', duration);
  //   this.Step = 8;
  // }

  scrollToGender(duration?:number) {
    this.animateScrollService.scrollToElement('scrollToGender', duration);
    this.Step = 6;
  }

  scrollToSmoker(duration?:number){
    this.animateScrollService.scrollToElement('scrollToSmoker', duration)
    this.Step = 7;
  }
  
  scrollToMedications(duration?:number){
    this.animateScrollService.scrollToElement('scrollToMedications', duration)
    this.Step = 8;
  }

  scrollToWH(duration?:number) {
    this.animateScrollService.scrollToElement('scrollToWH', duration)
    this.Step = 9;
  }

  scrollToHealth(duration?:number) {
    this.animateScrollService.scrollToElement('scrollToUserDetails', duration)
    this.Step = 10;
  }

  CheckInput(e,params){
    // if(params == 'zipcode'){
    //   let zipcode = e.target.value;
    //   if(zipcode != null && zipcode.length == 5){
    //     this.FormValues.setZipCode(zipcode);
    //   }
    // }else{
    //   let dob = e.target.value;
    //   if(dob.length == 10){
    //     this.FormValues.setDob(dob);
    //   }
    // }

    // if(this.FormValues.getDob().length == 5 && this.FormValues.getZipCode().length == 10){
    //   this.scrollToFaceAmount(600);
    // }
  }

  selectedFaceAmount(amount) {
    this.FormValues.setMonthlyContribution(amount);
    this.scrollToPolicyType(600);
  }

  selectedTermPolicy(policy){
    this.hidden_termLife = true;
    this.hidden_wholeUniversal = false;
    this.FormValues.HiddenQuestion = '';
    this.FormValues.setPolicy(policy);
    this.scrollToHiddenPolicyQuestion(600);

  }

  selectedPermanentPolicy(policy){
    this.hidden_wholeUniversal = true;
    this.hidden_termLife = false;
    this.FormValues.HiddenQuestion = '';
    this.FormValues.setPolicy(policy);
    this.scrollToHiddenPolicyQuestion(600);

  }

  conversionOption(value) {
    this.FormValues.HiddenQuestion = '';
    this.FormValues.setCoversionValue(value);
    this.scrollToDeathBenfits(600);
  }

  cashValue(value) {
    this.FormValues.HiddenQuestion = '';
    this.FormValues.setCashValue(value);
    this.scrollToDeathBenfits(600);
  }

  // selectedOccupation() {
  //   // this.FormValues.setOccupation(value);
  //   this.scrollToAgeToTakeIncome(600);
  // }

  selectedDeathBenifit() {
    // this.FormValues.setOccupation(value);
    this.scrollToGender(600);
  }

  selectedAgeToTakeIncome() {
    // this.FormValues.setOccupation(value);
    this.scrollToGender(600);
  }

  selectedGender(gender) {
    this.FormValues.setGender(gender);
    this.scrollToSmoker(600);
  }

  isSomker(smoker){
    this.FormValues.setSmoker(smoker);
    this.scrollToMedications(600);
  }
  
  mediCation(medication){
    this.FormValues.setMedication(medication);
    this.scrollToWH(600);
  }

  // selectedWeightHeight(weightheight) {
  //   this.FormValues.setWeightHeight(weightheight);
  //   this.scrollToHealth(600);
  // }

  // selectHealthCondition(event, value) {
  //   let _index = this.FormValues.HealthConditions.indexOf(value);

  //   if(event.target.checked && _index == -1) {
  //     if(value == 'None') {
  //       this.FormValues.resetHealthCondition();
  //     }
  //     else {
  //       // pop none out of health condition array
  //       let _none_index = this.FormValues.HealthConditions.indexOf('None');

  //       if(_none_index > -1) {
  //         this.FormValues.HealthConditions.splice(_none_index, 1);
  //       }
  //     }

  //     this.FormValues.setHealthCondition(value);
  //   }
  //   else {
  //     if(_index > -1) {
  //       this.FormValues.HealthConditions.splice(_index, 1);
  //     }
  //   }
  // }
  handleAddressChange(event){
    let street_address = '';

    for(let i = 0; i < event.address_components.length; i++) {
      if(event.address_components[i].types != undefined) {
        for(let j = 0; j < event.address_components[i].types.length; j++) {
          if(event.address_components[i].types[j] == "postal_code") {
            this.FormValues.ZipCode = event.address_components[i].long_name;
          }

          if(event.address_components[i].types[j] == "locality") { // city
            this.FormValues.City = event.address_components[i].long_name;
          }

          if(event.address_components[i].types[j] == "administrative_area_level_1") { // state
             this.FormValues.State = event.address_components[i].long_name;
          }

          if(event.address_components[i].types[j] == "country") { // country
            this.FormValues.Country = event.address_components[i].long_name;
          }

          // for address
          if(event.address_components[i].types[j] == "street_number") { 
            street_address += ' ' + event.address_components[i].long_name;
          }

          if(event.address_components[i].types[j] == "route") { 
            street_address += ' ' + event.address_components[i].long_name;
          }

          if(event.address_components[i].types[j] == "sublocality_level_1") { 
            street_address += ' ' + event.address_components[i].long_name;
          }

          if(event.address_components[i].types[j] == "sublocality_level_2") { 
            street_address += ' ' + event.address_components[i].long_name;
          }
        }
      }
    }
    
    this.FormValues.Address = street_address;
    
     this.checkZip();
    //  this.checkDob();
  }

  CheckAddressInput(event){
    if(event.target.value==''){
      this.FormValues.ZipCode=null;
    }
    
  }

  checkDob() {

    if(this.FormValues.Dob != null) {
      // this.scrollToFaceAmount(600);
      this.scrollToTop(600);
    }
  }

  checkCarrier() {

    if(this.FormValues.CarrierName != null) {
      // this.scrollToFaceAmount(600);
      this.scrollToPolicyType(600);

    }
  }

  checkZip() {
    
    if(this.FormValues.ZipCode != null) {
      // this.scrollToFaceAmount(600);
      this.scrollToCarrier(600);

    }
  }
  

  // checkIncome() {
  //   if(this.FormValues.annualIncome != null && this.FormValues.desiredIncome != null) {
  //     this.scrollToFaceAmount(600);
  //   }
  // }

  checkWeightHeight(value = null,which = null) {
    // if(which == 'HeightFeet'){
    //   this.FormValues.setHeightInFeet(value);
    // }else if(which == 'HeightInch'){
    //   if(!this.FormValues.HeightInFeet){
    //     this.FormValues.setHeightInFeet(4);
    //   }
    //   this.FormValues.setHeightInInch(value);
    // }
    
    if(this.FormValues.setWeight == null || this.FormValues.HeightInFeet == null || this.FormValues.HeightInInch == null ){
      return;
    }
    else if(this.FormValues.Weight != null && this.FormValues.HeightInFeet != null && this.FormValues.HeightInInch != null) {
      this.scrollToHealth(600);
    }
  }

  // unSelectAll(value){

  // }

  scrollForCompletedFileds() {
    if(this.FormValues.Weight != null && this.FormValues.HeightInFeet != null && this.FormValues.HeightInInch != null) {
      this.scrollToHealth(600);
    }
    else if(this.FormValues.Medication != null) {
      this.scrollToMedications(600);
    }
    else if(this.FormValues.Smoker != null) {
      this.scrollToWH(600);
    }
    else if(this.FormValues.Gender != null) {
      this.scrollToSmoker(600);
    }
    else if(this.FormValues.Term != null) {
      this.scrollToGender(600);
    }
    // else if (this.FormValues.desiredIncome != null && this.FormValues.annualIncome != null) {
    //   this.scrollToFaceAmount(600);
    // }
    else if(this.FormValues.ZipCode != null && this.FormValues.Dob != null) {
      this.scrollToPolicyType(600);
    }
  }

  checkHealthConditionExists(value) {
    let _index = this.FormValues.HealthConditions.indexOf(value);
    if(_index > -1) {
      return true;
    }
    else {
      return false;
    }
  }
  SaveLead(){
    
      // save the lead and move to thank you page
      let data = {
        // __key: this.AppGlobals.spa_id,
        full_name: this.FormValues.FirstName + " " + this.FormValues.LastName,
        email: this.FormValues.Email,
        phone: this.FormValues.PhoneNumber,
        address: this.FormValues.Address,
        city: this.FormValues.City,
        state: this.FormValues.State,
        country: this.FormValues.Country,
        dob: this.FormValues.Dob,
        gender: this.FormValues.Gender,
        monthly_contribution_amount: this.FormValues.MonthlyContribution, //monthly contribution amount
        tobacco: this.FormValues.Smoker,
        zip_code: this.FormValues.ZipCode,
        weightLbs: this.FormValues.Weight,
        heightFt: this.FormValues.HeightInFeet,
        heightIn: this.FormValues.HeightInInch,
        medications: this.FormValues.Medication,
        occupation: this.FormValues.occupation,
        age_to_take_income: this.FormValues.AgeToTakeIncome,
        annualIncome: this.FormValues.annualIncome,
        desiredIncome: this.FormValues.desiredIncome,
        PolicyType: this.FormValues.PolicyType,
        HiddenQuestion: this.FormValues.HiddenQuestion,
        DeathBenefit: this.FormValues.DeathBenefit,
        health_info : this.FormValues.HealthConditions,
        CarrierName : this.FormValues.CarrierName,
        __key: 'bed772bef667754e0655fb63ea5190e0'
        // send the lirp specific values

      }
      
      // this.loading = true;
  
      this._QuoteService.AddLead(data).subscribe(
        result => {
          let _data = result['data'];
          let _res = null;
          let _utmData;

          if(result['success']) {
            this.FormValues.lead_id = result['data'].lead_id;
            this.isLoading = false;
            // this.router.navigate(['thank-you']);
            if(this.FormValues.utm_source!=null){
              _utmData = {
                utm_source: this.FormValues.utm_source,
                utm_campaign: this.FormValues.utm_campaign,
                utm_medium: this.FormValues.utm_medium,
                utm_content: this.FormValues.utm_content,
                utm_term: this.FormValues.utm_term,
                referred: this.FormValues.referred,
                lead_id: _data['lead_id'],
                // ...this.QueryParams,
                organization_id: '5b32ddde9c779',
                entity_type: 'LIRP SPA',
              }
            }else{
              _utmData = {
                utm_source: null,
                utm_campaign: null,
                utm_medium: null,
                utm_content: null,
                utm_term: null,
                referred: this.FormValues.referred,
                lead_id: _data['lead_id'],
                // ...this.QueryParams,
                organization_id: '5b32ddde9c779',
                entity_type: 'LIRP SPA',
              }
            }
            this._UtmService.SendUtmData(_utmData);
            this.router.navigate(['thank-you']);



          }
        }
      );
      
    
  }

  submitForm() {
    console.log('error');
    let errors = this.FormErrors = this.validateForm();
    if(
      !errors.dob &&
      !errors.zip_code &&
      !errors.age_to_take_income &&
      !errors.gender &&
      !errors.smoker &&
      !errors.medication &&
      !errors.weight &&
      !errors.height_and_weight &&
      !errors.health_conditions &&
      !errors.name &&
      !errors.contact_details &&
      !errors.policy_type &&
      !errors.hidden_policy_question &&
      !errors.death_benifits&&
      !errors.carrier_name
      // !errors.health_conditions
    ) {
      this.HeathQuestionModalShow();

    }
    else if(errors.dob) {

      this.scrollToDob(600);
    }
    else if(errors.carrier_name) {

      this.scrollToCarrier(600);
    }
    else if(errors.zip_code) {

      this.scrollToTop(600);
    }
    // else if(errors.annual_income || errors.desired_income) {

    //   this.scrollToIncome(600);
    // }
    // else if(errors.monthly_contribution) {

    //   this.scrollToFaceAmount(600);
    // }
    else if(errors.policy_type) {

      this.scrollToPolicyType(600);
    }
    else if(errors.hidden_policy_question) {

      this.scrollToHiddenPolicyQuestion(600);
    }
    else if(errors.death_benifits) {

      this.scrollToDeathBenfits(600);
    }
    // else if(errors.occupation) {

    //   this.scrollToOccupation(600);
    // }
    // else if(errors.age_to_take_income) {

    //   this.scrollToAgeToTakeIncome(600);
    // }
    else if(errors.gender) {

      this.scrollToGender(600);
    }
    else if(errors.smoker) {

      this.scrollToSmoker(600);
    }
    else if(errors.medication) {

      this.scrollToMedications(600);
    }
    else if(errors.height_and_weight) {

      this.scrollToWH(600);
    }
    // this.shared.communicator.next(0);
  }

  HeathQuestionModalShow() {
    let qualifyModal: any = document.querySelector('#qualifyModal');
    document.body.style.overflowY = "hidden";

    qualifyModal.classList.add('show');
  }

  HeathQuestionModalClose() {
    let qualifyModal: any = document.querySelector('#qualifyModal');
    document.body.style.overflowY = "scroll";

    qualifyModal.classList.remove('show');
  }

  selectHealthCondition(event, value) {
    let _index = this.FormValues.HealthConditions.indexOf(value);

    if(event.target.checked && _index == -1) {
      if(value == 'None') {
        this.FormValues.resetHealthCondition();
      }
      else {
        // pop none out of health condition array
        let _none_index = this.FormValues.HealthConditions.indexOf('None');

        if(_none_index > -1) {
          this.FormValues.HealthConditions.splice(_none_index, 1);
        }
      }

      this.FormValues.setHealthCondition(value);
    }
    else {
      if(_index > -1) {
        this.FormValues.HealthConditions.splice(_index, 1);
      }
    }
  }

  continueNext() {
    this.isLoading = true;
    if(this.FormValues.HealthConditions.length < 1) {
      this.FormValid = false;
      this.FormErrors.health_conditions = true;
    }
    else {
      this.FormErrors.health_conditions = false;
      document.body.style.overflowY = "scroll";

      this.SaveLead();

    }
  }


  formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
    try {
      amount = amount.replace(',', '');
      amount = amount.replace('.', '');
      amount = amount.replace(',', '');
      amount = amount.replace(',', '');
      amount = amount.replace(',', '');
      amount = amount.replace(',', '');
      amount = amount.replace(',', '');
      amount = amount.replace(',', '');
      amount = amount.replace(',', '');
      amount = amount.replace(',', '');

      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
      const negativeSign = amount < 0 ? "-" : "";
  
      let i:any = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
      
      let value =  (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands);
      return value;
    } 
    catch (e) {
    }
  }

  validateForm() {
    this.FormValid = true;
    let error: any = {
      "zip_code": false,
      "dob": false,
      "gender": false,
      "smoker": false,
      "medication": false,
      "weight": false,
      "height_and_weight": false,
      "health_conditions": false,
      "name": false,
      "contact_details": false,
      "policy_type": false,
      "hidden_policy_question": false,
      "death_benifits": false,
      "carrier_name": false,
    };

    
    if(this.FormValues.Dob == null || this.FormValues.Dob == '') {
      this.FormValid = false;
      error.dob = true;
    }
    else {
      error.dob = false;
    }

    if(this.FormValues.CarrierName == null || this.FormValues.CarrierName == '') {
      this.FormValid = false;
      error.carrier_name = true;
    }
    else {
      error.carrier_name = false;
    }

    if(this.FormValues.ZipCode == null || this.FormValues.ZipCode == '') {
      this.FormValid = false;
      error.zip_code = true;
    }
    else {
      error.zip_code = false;
    }

    if(this.FormValues.Gender == null || this.FormValues.Gender == '') {
      this.FormValid = false;
      error.gender = true;
    }
    else {
      error.gender = false;
    }

    if(this.FormValues.Smoker == null || this.FormValues.Smoker == '') {
      this.FormValid = false;
      error.smoker = true;
    }
    else {
      error.smoker = false;
    }

    if(this.FormValues.Medication == null || this.FormValues.Medication == '') {
      this.FormValid = false;
      error.medication = true;
    }
    else {
      error.medication = false;
    }

    if(this.FormValues.Weight == null || this.FormValues.Weight == '') {
      this.FormValid = false;
      error.weight = true;
    }
    else {
      error.weight = false;
    }

    if(this.FormValues.HeightInFeet == null || this.FormValues.HeightInInch == null ) {
      this.FormValid = false;
      error.height_and_weight = true;
    }
    else {
      error.height_and_weight = false;
    }

    if(this.FormValues.FirstName == null || this.FormValues.LastName == null || this.FormValues.FirstName == '' || this.FormValues.LastName == '') {
      this.FormValid = false;
      error.name = true;
    }
    else {
      error.name = false;
    }

    if(
      this.FormValues.Email == null || 
      this.FormValues.Email == '' || 
      !this.emailIsValid(this.FormValues.Email) ||
      this.FormValues.PhoneNumber == null || 
      this.FormValues.PhoneNumber == '' || 
      !this.phoneIsValid(this.FormValues.PhoneNumber)
    ) {
      this.FormValid = false;
      error.contact_details = true;
    }
    else {
      error.contact_details = false;
    }

    if(this.FormValues.PolicyType == null || this.FormValues.PolicyType == '') {
      this.FormValid = false;
      error.policy_type = true;
    }
    else {
      error.policy_type = false;
    }

    if(this.FormValues.HiddenQuestion == null || this.FormValues.HiddenQuestion == '') {
      this.FormValid = false;
      error.hidden_policy_question = true;
    }
    else {
      error.hidden_policy_question = false;
    }

    if(this.FormValues.DeathBenefit == null || this.FormValues.DeathBenefit == '') {
      this.FormValid = false;
      error.death_benifits = true;
    }
    else {
      error.deat = false;
    }
    
    return error;
  }

  emailIsValid (email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  phoneIsValid (phone) {
    return phone.length == 10 ? true : false;
  }

  // UnSelectAll(){
  //   let items=document.getElementsByName('any');
    
  //   for(let i=0; i<items.length; i++){
  //     if(items[i].type=='checkbox')
  //       items[i].checked=false;
  //   }
  // }
}
