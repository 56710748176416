<header>
  <nav class="navbar spa-main-nav navbar-expand-md">
     <!-- Brand -->
     <a class="navbar-brand spa-navbar-brand-img" [routerLink]="['/']">
     <img src="assets/images/insgeek.png">
     </a>
  </nav>
</header>
<section class="quiz">
  <div class="spa-qualify-quiz-container">
     <div class="container">
        <h2 class="doIQualify-heading">Do I qualify</h2>
        <form>
            <div *ngFor="let question of QuestionsData" class="spa-quiz-citizen-container pt-4rem" [class.first-question-do-i-qualify]="question.sl == 1" [id]="'Question_'+question.question_id">
               <h3>{{ question.sl }}) {{ question.question }} </h3>
               <div class="spa-quiz-ans" *ngIf="question.type == 'yes_no'">
                  <span *ngFor="let option of question.options" [class.highlight-btn]="option.value == getSelectedAnswer(question.question_id)" (click)="SelectOption(question.question_id, option.value, question.sl)">{{ option.label }}</span>
               </div>

               <div class="spa-quiz-ans-checkbox" *ngIf="question.type == 'radio'">
                  <div class="ml-media-575 p-8rem">
                     <div class="res-d-inline">
                        <div class="criminal-info" *ngFor="let option of question.options">
                           <label class="quiz-chkbox-con">{{ option.label }}
                           <input type="checkbox" [value]="option.value" 
                            (change)="SelectOption(question.question_id, option.value, question.sl, 'select', $event)"
                            [checked]="option.value == getSelectedAnswer(question.question_id)">
                           <span class="quiz-checkmark"></span>
                           </label>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="spa-quiz-ans-checkbox" *ngIf="question.type == 'nested'">
                  <div class="ml-media-575 p-8rem">
                     <div class="res-d-inline">
                        <div class="criminal-info" *ngFor="let nested_question of question.nested_questions">
                           <label class="quiz-chkbox-con quiz-chkbox-con-nested">{{ nested_question.sl }}) {{ nested_question.text }}
                           </label>
                           <div class="spa-quiz-ans spa-quiz-ans-nested" *ngIf="nested_question.type == 'yes_no'">
                              <span *ngFor="let option of nested_question.options" [class.highlight-btn]="option.value == getSelectedAnswer(nested_question.question_id)" (click)="SelectNestedQuestionOption(question.question_id, nested_question.question_id, option.value, question.sl)">{{ option.text }}</span>
                           </div>
                           <!-- <input type="checkbox" [value]="option.value" 
                            (change)="SelectOption(question.question_id, option.value, question.sl, 'select', $event)"
                            [checked]="option.value == getSelectedAnswer(question.question_id)">
                           <span class="quiz-checkmark"></span> -->
                        </div>
                     </div>
                  </div>
               </div>
               <p class="error-msg" *ngIf="checkError(question.question_id, question.type) && IsCheckNowClicked">*Please select a value.</p>
            </div>
          
           <div class="check-quiz-btn-con">
             <button type="button" 
               (click)="CheckQualify(_DoIQualifyFormModel)" 
               class="check-quiz-btn">
               Check Now
            </button>
           </div>
          </form>
     </div>
  </div>
</section>

<div class="modal_qualify_overlay" id="qualifyModal">
   <div class="modal_qualify">
      <div class="modal_qualify_header
         d-flex
         justify-content-end">
         <button class="btn btn__close"
            (click)="modalClose()">
            <i class="fa fa-times" aria-hidden="true"></i>
         </button>
      </div>

      <div class="modal_qualify_body">
         <form class="form_modal_qualify">
            <div class="inputGrp mb-3">
               <label class="qualify_label
                  text-uppercase">
                  Name</label>
               <input type="text" 
                  class="qualify_form_input
                  rounded-0
                  px-0
                  form-control" 
                  name="name"
                  placeholder="Name" name="FullName" [(ngModel)]="_DoIQualifyFormModel.FullName">
            </div>
            <p class="error-msg mb-100" *ngIf="FormErrors != null && FormErrors.FullName" style="margin-top: -5px;margin-left: -5px; color: red;font-size: 16px;">*Please enter full name.</p>

            <div class="inputGrp mb-3">
               <label class="qualify_label
                  text-uppercase">
                  Street
               </label>
               <input type="text" 
                  class="qualify_form_input
                  rounded-0
                  px-0
                  form-control" 
                  name="street"
                  placeholder="Street"  name="Street" [(ngModel)]="_DoIQualifyFormModel.Street">
            </div>
            <p class="error-msg mb-100" *ngIf="FormErrors != null && FormErrors.Street" style="margin-top:  -5px;margin-left: -5px; color: red;font-size: 16px;">*Please enter Street.</p>

            <div class="inputGrp mb-3">
               <label class="qualify_label
                  text-uppercase">
                  City
               </label>
               <input type="text" 
                  class="qualify_form_input
                  rounded-0
                  px-0
                  form-control" 
                  name="city"
                  placeholder="City"  name="City" [(ngModel)]="_DoIQualifyFormModel.City">
            </div>

            <p class="error-msg mb-100" *ngIf="FormErrors != null && FormErrors.City" style="margin-top:  -5px;margin-left: -5px; color: red;font-size: 16px;">*Please enter City.</p>

            <div class="inputGrp mb-3">
               <label class="qualify_label
                  text-uppercase">
                  State
               </label>
               <input type="text" 
                  class="qualify_form_input
                  rounded-0
                  px-0
                  form-control" 
                  name="state"
                  placeholder="State"  name="State" [(ngModel)]="_DoIQualifyFormModel.State">
            </div>
            <p class="error-msg mb-100" *ngIf="FormErrors != null && FormErrors.State" style="margin-top:  -5px;margin-left: -5px;color: red;font-size: 16px;">*Please enter State.</p>

            <div class="inputGrp mb-4">
               <label class="qualify_label
                  text-uppercase">
                  Zip
               </label>
               <input type="text" 
                  class="qualify_form_input
                  rounded-0
                  px-0
                  form-control" 
                  name="zip"
                  placeholder="Zip"  name="ZipCode" [(ngModel)]="_DoIQualifyFormModel.ZipCode">
            </div>
            <p class="error-msg mb-100" *ngIf="FormErrors != null && FormErrors.ZipCode" style="margin-top:  -25px;margin-left: -3px;color: red;font-size: 16px;">*Please enter ZipCode.</p>

            <div class="btnGrp 
               check-quiz-btn-con
               text-right 
               py-0">
               <button class="check-quiz-btn
                  fs-small
                  px-3"
                  (click)="SendMeApplication(_DoIQualifyFormModel)">
                  Send me application
               </button>
            </div>
         </form>
      </div>
   </div>
</div>


<div class="progress-bar">
   <span class="steps" *ngFor="let question of QuestionsData" [class.active-step]="Step == question.question_id"></span>
</div>