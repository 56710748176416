import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LeadFormModel } from 'src/app/models/LeadForm';
import { QuoteService } from 'src/app/services/quote.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-conditional-modal',
  templateUrl: './conditional-modal.component.html',
  styleUrls: ['./conditional-modal.component.css']
})
export class ConditionalModalComponent implements OnInit {

  public FormValid: boolean = false;
  public FormErrors: any = null;

  public Plans = this.fb.group({
    'lead_id' : [''],
    'cd_yes_no' : [null],
    '401k_yes_no' : [null],
    'sep_yes_no' : [null],
    'brokerage_account_yes_no' : [null],
    'municipal_bonds_yes_no' : [null],
    'traditional_ira_yes_no' : [null],
    'roth_ira_yes_no' : [null],
    'fixed_annuities_yes_no' : [null],
    'variable_annuities_yes_no' : [null],
    'saving_account_yes_no' : [null],
    'pension_yes_no' : [null],
    'none': [null]
  })

  constructor(
    public router: Router,
    public bsModalRef: BsModalRef,
    public FormValues: LeadFormModel = new LeadFormModel,
    public shared: SharedService,
    public _QuoteSerive: QuoteService,
    public fb: FormBuilder
  ) { }

  ngOnInit() {
  }

  checkInsuranceExists(value) {
  }

  selectInsurancePlan(event, value) {
    if(this.Plans.value.none==true){
      this.Plans.value.cd_yes_no = null;
      this.Plans.value['401k_yes_no'] = null;
      this.Plans.value.sep_yes_no = null;
      this.Plans.value.brokerage_account_yes_no = null;
      this.Plans.value.municipal_bonds_yes_no = null;
      this.Plans.value.traditional_ira_yes_no = null;
      this.Plans.value.roth_ira_yes_no = null;
      this.Plans.value.fixed_annuities_yes_no = null;
      this.Plans.value.variable_annuities_yes_no = null;
      this.Plans.value.variable_annuities_yes_no = null;
      this.Plans.value.saving_account_yes_no = null;
      this.Plans.value.pension_yes_no = null;
    }
  }

  continueNext() {
   
    if(this.Plans.value.cd_yes_no == null &&
      this.Plans.value['401k_yes_no'] == null &&
      this.Plans.value.sep_yes_no == null &&
      this.Plans.value.brokerage_account_yes_no == null &&
      this.Plans.value.municipal_bonds_yes_no == null &&
      this.Plans.value.traditional_ira_yes_no == null &&
      this.Plans.value.roth_ira_yes_no == null &&
      this.Plans.value.fixed_annuities_yes_no == null &&
      this.Plans.value.variable_annuities_yes_no == null &&
      this.Plans.value.variable_annuities_yes_no == null &&
      this.Plans.value.saving_account_yes_no == null &&
      this.Plans.value.pension_yes_no == null &&
      this.Plans.value.none == null){
        this.FormErrors = true;
        return;
      }

    this.Plans.value.lead_id = this.FormValues.lead_id;
    this._QuoteSerive.saveRetirementPlan(this.Plans.value).subscribe(
      result=>{
        console.log(result);
        this.shared.communicator.next(1);
        this.bsModalRef.hide();
      }
    )

  }


}
