<header>
    <nav class="navbar spa-main-nav navbar-expand-md">
       <!-- Brand -->
       <a class="navbar-brand spa-navbar-brand-img" [routerLink]="['/']">
            <img src="assets/images/insgeek.png">
       </a>
    </nav> 
</header>

<section>
    <div class="banner-section">
       <div class="container">
          <form [formGroup]="applyForm" [class.formSubmitted]="formSubmitted" (ngSubmit)="AddLead()">
             <div class="apply-form-container">
                <div class="apply-input-container pb-6rem pt-8rem">
                   <label>Your name</label>
                   <input type="text" name="name" formControlName="full_name" placeholder="Your Name" class="apply-name-input">
                </div>
                <div class="apply-input-container pb-6rem">
                    <label>Your email</label>
                    <input type="text" name="name" formControlName="email" placeholder="Email" class="apply-name-input">
                 </div>
                 <div class="apply-input-container">
                    <label>Your phone number</label>
                    <input type="text" name="name" formControlName="phone" mask='(000) 000-0000' placeholder="Phone Number" class="apply-name-input">
                 </div>
                 <div class="send-agent-btn-container">
                     <button type="submit" class="send-agent-btn" (click)="AddLead();">Send To Agent</button>
                 </div>
                 <!-- <section>
                     <div *ngIf="active_popup">
                        <div class="popup-modal-container" [class.hide-modal]="MenuStateValues.getIsOpen()">
                           <div class="popup-modal">
                              <button class="modal-close-btn" (click)="MenuStateValues.open()"[routerLink]="['/']">&#10005;</button>
                              <div class="success-apply-text">
                                 <h4>Thank you for reaching us</h4>
                                 <p>We will get back to you</p>
                              </div>
                           </div>
                        </div>
                     </div>
                 </section> -->
             </div>
          </form>
       </div>
    </div>
</section>
<div class="loader-container" *ngIf="loading">
   <div class="loader"></div>
</div>