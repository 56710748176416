<header>
    <nav class="navbar spa-main-nav navbar-expand-md">
       <!-- Brand -->
       <a class="navbar-brand spa-navbar-brand-img" [routerLink]="['/']">
          <img src="assets/images/thank-you-logo.png">
       </a>
    </nav>
 </header>

<section>
    <div class="success-main-container pt-5rem">
        <div class="col-xl-12 col-12 d-flex flex-wrap">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 m-auto">
               <div class="success-image-container">
                   <img src="assets/images/success-1.jpg">
                   <p>Thanks for your Quote Request. We will be in touch shortly.</p>
               </div>
            </div>
        </div>
        <div class="col-xl-12 col-12 d-flex flex-wrap" style="justify-content: center;align-items: center;">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 m-auto">
                <div class="success-image-two-container mt-5">
                    <h5 class="success-image-two-title">Our Recommendations</h5>
                    <div class="d-flex flex-wrap success-card-image-two-container">
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                          <a href="https://www.insurancegeek.com/getting-started">
                            <div class="card success-card">
                                <img class="card-img-top" src="assets/images/success-2.jpg" alt="Card image cap">
                                <div class="card-body success-card-body">
                                  <h5 class="card-title success-card-title">Getting Started</h5>
                                  <p class="card-text success-card-text">Ultimate Steps: Everything you need to know before you get started with life insurance.</p>
                                </div>
                              </div>
                           </a>
                           <div class="text-center">
                              <a href="https://www.insurancegeek.com/getting-started" class="btn btn-learn">Learn More</a>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                          <a href="https://www.insurancegeek.com/guides">
                            <div class="card success-card">
                              <img class="card-img-top" src="assets/images/success-3.jpg" alt="Card image cap">
                              <div class="card-body success-card-body">
                                <h5 class="card-title success-card-title">Guides</h5>
                                <p class="card-text success-card-text">Looking for A Guide: We have put together guides to make learning life insurance easy.  See the list.</p>
                              </div>
                            </div>
                          </a>
                          <div class="text-center">
                            <a href="https://www.insurancegeek.com/guides" class="btn btn-learn">Learn More</a>
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                          <a href="https://www.insurancegeek.com/carrier-reviews/best-life-insurance-companies">
                            <div class="card success-card">
                              <img class="card-img-top" src="assets/images/success-4.jpg" alt="Card image cap">
                              <div class="card-body success-card-body">
                                <h5 class="card-title success-card-title">Top Company 2020</h5>
                                <p class="card-text success-card-text">See our list of Best life insurance companies in 2020. See best term life, whole life, universal life, no exam, and best-rated companies in the US.</p>
                              </div>
                            </div>
                          </a>
                          <div class="text-center">
                            <a href="https://www.insurancegeek.com/carrier-reviews/best-life-insurance-companies" class="btn btn-learn">Learn More</a>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>