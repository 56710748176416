<header>
    <nav class="navbar spa-main-nav navbar-expand-md">
       <!-- Brand -->
       <a class="navbar-brand spa-navbar-brand-img" [routerLink]="['/']">
          <img src="assets/images/insgeek.png">
       </a>
    </nav>
 </header>
 <section>
    <div class="spa-form-result-container">
       <div class="d-flex flex-wrap">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 spa-form-result-left-container">
             <h3>Your Inputs</h3>
             <div class="invesment-content-1">
                <span *ngIf="!isEditable" (click)="editFields(); ResultShowValues.open()" class="result-edit">Edit</span>
                <div class="result-zip custom-transition result-res-view mb-3" [class.result-res-view-open]="ResultShowValues.getIsOpen()">
                   <p>Zip Code</p>
                   <input *ngIf="FormValues.ZipCode != null" type="number" onKeyPress="if(this.value.length==5) return false;" [readonly]="!isEditable" [(ngModel)]="FormValues.ZipCode" [class.error-txt-bx]="FormErrors.zip_code" class="result-editable-input"/>
                   <input *ngIf="FormValues.ZipCode == null" type="number" onKeyPress="if(this.value.length==5) return false;" [readonly]="!isEditable" [(ngModel)]="FormValues.ZipCode" [class.error-txt-bx]="FormErrors.zip_code" placeholder="Enter Input" class="result-editable-input"/>
                </div>
                <div class="result-dob custom-transition result-res-view mb-3" [class.result-res-view-open]="ResultShowValues.getIsOpen()">
                   <p>Date Of Birth</p>
                   <input *ngIf="FormValues.Dob != null" type="text" name="DOB" [readonly]="!isEditable" class="result-editable-input" [class.error-txt-bx]="FormErrors.dob" (change)="FormValues.setDob($event.target.value)" [value]="FormValues.getDob()" mask='00/00/0000' placeholder="MM/DD/YYYY" #dateInput>
                   <!-- <input *ngIf="FormValues.Dob != null" type="text" [readonly]="!isEditable" [class.error-txt-bx]="FormErrors.dob" [(ngModel)]="FormValues.Dob" mask='00/00/0000' class="result-editable-input"/> -->
                   <input *ngIf="FormValues.Dob == null" type="text" [readonly]="!isEditable" [class.error-txt-bx]="FormErrors.dob" placeholder="MM/DD/YYYY" (change)="FormValues.setDob($event.target.value)" [value]="FormValues.Dob"  mask='00/00/0000' class="result-editable-input"/> 
                </div>
                <div class="result-face-amount custom-transition mb-3">
                   <p>Face Anmount</p>
                   <span *ngIf="FormValues.CoverageAmount != null && !isEditable" class="edit-options"> ${{ FormValues.CoverageAmount }} </span>
                   <span *ngIf="FormValues.CoverageAmount == null && !isEditable" class="edit-options">No input</span>
                   <select [(ngModel)]="FormValues.CoverageAmount" [class.error-select-bx]="FormErrors.coverage_amount" *ngIf="isEditable" class="edit-options-select">
                     <option value="5000">$ 5,000</option>
                     <option value="10000">$ 10,000</option>
                     <option value="15000">$ 15,000</option>
                     <option value="20000">$ 20,000</option>
                     <option value="25000">$ 25,000</option>
                   </select>
                </div>
                <div class="result-gender custom-transition result-res-view mb-3" [class.result-res-view-open]="ResultShowValues.getIsOpen()">
                   <p>Gender</p>
                   <span *ngIf="FormValues.Gender != null && !isEditable" class="edit-options"> {{ FormValues.Gender }} </span>
                   <span *ngIf="FormValues.Gender == null && !isEditable" class="edit-options">No input</span>
                   <select [(ngModel)]="FormValues.Gender" [class.error-select-bx]="FormErrors.gender" *ngIf="isEditable" class="edit-options-select">
                     <option value="male">Male</option>
                     <option value="female">Female</option>
                   </select>
                </div>
                <div class="result-smoke custom-transition result-res-view mb-3" [class.result-res-view-open]="ResultShowValues.getIsOpen()">
                   <p>Smoke</p>
                   <span *ngIf="FormValues.Smoker != null && !isEditable" class="edit-options"> {{ FormValues.Smoker }} </span>
                   <span *ngIf="FormValues.Smoker == null && !isEditable" class="edit-options">No input</span>
                   <select [(ngModel)]="FormValues.Smoker" [class.error-select-bx]="FormErrors.smoker" *ngIf="isEditable" class="edit-options-select">
                     <option value="yes">Yes</option>
                     <option value="no">No</option>
                   </select>
                </div>

                <div class="result-smoke custom-transition result-res-view" [class.result-res-view-open]="ResultShowValues.getIsOpen()">
                  <p>Medications</p>
                  <span *ngIf="FormValues.Medication != null && !isEditable" class="edit-options"> {{ FormValues.Medication }} </span>
                  <span *ngIf="FormValues.Medication == null && !isEditable" class="edit-options">No input</span>
                  <select [(ngModel)]="FormValues.Medication" [class.error-select-bx]="FormErrors.medication" *ngIf="isEditable" class="edit-options-select">
                     <option value="yes">Yes</option>
                     <option value="no">No</option>
                  </select>
               </div>

                <div class="result-zip custom-transition result-res-view mb-3 mt-3" [class.result-res-view-open]="ResultShowValues.getIsOpen()">
                   <p>Weight</p>
                   <input *ngIf="FormValues.Weight != null" type="text" maxlength="3" [readonly]="!isEditable" [class.error-txt-bx]="FormErrors.weight" [(ngModel)]="FormValues.Weight" class="result-editable-input"/>
                   <input *ngIf="FormValues.Weight == null" type="text" maxlength="3" [readonly]="!isEditable" [class.error-txt-bx]="FormErrors.weight" [(ngModel)]="FormValues.Weight" placeholder="Enter Weight" class="result-editable-input"/>
                </div>
                <div class="result-smoke custom-transition result-res-view mb-3" [class.result-res-view-open]="ResultShowValues.getIsOpen()">
                   <p>Height</p>
                   <!-- <span *ngIf="FormValues.HeightInFeet == null && !isEditable" class="edit-options">No input</span> -->
                   <select [(ngModel)]="FormValues.HeightInFeet" [class.error-select-bx]="FormErrors.height_feet" *ngIf="isEditable" class="edit-options-select-height">
                     <option value="4">4 Feet</option>
                     <option value="5">5 Feet</option>
                     <option value="6">6 Feet</option>
                     <option value="7">7 Feet</option>
                   </select>
                   <select [(ngModel)]="FormValues.HeightInInch" [class.error-select-bx]="FormErrors.height_inch" *ngIf="isEditable" class="edit-options-select-height">
                     <option value="0">0 Inch</option>
                     <option value="1">1 Inch</option>
                     <option value="2">2 Inch</option>
                     <option value="3">3 Inch</option>
                     <option value="4">4 Inch</option>
                     <option value="5">5 Inch</option>
                     <option value="6">6 Inch</option>
                     <option value="7">7 Inch</option>
                     <option value="8">8 Inch</option>
                     <option value="9">9 Inch</option>
                     <option value="10">10 Inch</option>
                     <option value="11">11 Inch</option>
                   </select>
                   <div class="d-inline-block" *ngIf="!isEditable">
                      <span class="edit-options" *ngIf="FormValues.HeightInFeet != null">{{ FormValues.HeightInFeet }}'</span>
                      <span class="edit-options" *ngIf="FormValues.HeightInFeet == null">0'</span>
                      <span class="edit-options" *ngIf="FormValues.HeightInInch != null">{{ FormValues.HeightInInch }}"</span>
                      <span class="edit-options" *ngIf="FormValues.HeightInInch == null">0"</span>
                   </div>
                </div>
                <button *ngIf="isEditable" (click)="editComplete(); ResultShowValues.close()" class="resdult-finish-edit">Go</button>
             </div>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 spa-form-result-right-container">
             <div class="result-rates-details-container">
               <div *ngFor="let quote of QuoteResults" class="col-xl-12 col-12 result-details-output mb-4col-xl-12 col-12 result-details-output mb-4 mt-2">
                     <div class="result-details-output-container d-flex flex-wrap">
                        <div class="result-company-image col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                           <img *ngIf="quote.company_id == 10" src="assets/images/assurity_logo.png">
                           <img *ngIf="quote.company_id == 14" src="assets/images/fidelity-life-logo.png">
                           <img *ngIf="quote.company_id == 43" src="assets/images/phoenix-life-logo.png">
                           <img *ngIf="quote.company_id == 20" src="assets/images/lafayette-logo.png">
                        </div>
                        <div class="res-result-div col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 ">
                           <div class="result-term-year-details result-details-output" *ngIf="_i != 3">
                              <div class="result-term-year-details result-details-output">
                                 <span class="result-text-span">{{ quote.product }}</span>
                              </div>
                              <span class="result-amount">$ {{ quote.monthly_policy_total }}/month</span>
                           </div>
                        </div>
                        <div class="res-result-div col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                           <div class="result-term-year-details result-details-output" *ngIf="_i != 3">
                              <div>
                                 <button type="button" class="apply-details-btn" (click)="apply(quote, quote.company_id)">Do I Qualify</button>
                              </div> 
                           </div>
                        </div>
                     </div>
               </div>
             </div>
             <div *ngIf="message != null && this.QuoteResults.length == 0" class="col-xl-12 col-12 result-details-output-container2 px-0 d-flex flex-wrap">
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 result-details-output">
                  <div class="result-term-title">
                     <h4 style="text-align: left;">Error</h4>
                     <p style="text-align: left;">{{ message }}</p>
                  </div>
               </div>
            </div>
             <!-- <div *ngIf="message == null" class="result-send-quote">
                <form [formGroup]="sendQuote" [class.formSubmitted]="formSubmitted" (ngSubmit)="sendQuote()">
                  <div class="row">
                     <div class="col-xl-12 col-12 sendquote-input-title mb-4">
                        <label>Send me this quote</label>
                     </div>
                     <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 sendquote-input-container">
                        <label>Name</label>
                        <input type="text" name="name" formControlName="full_name" placeholder="Your Name" class="send-quote-input">
                     </div>
                     <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 sendquote-input-container">
                        <label>Email</label>
                        <input type="text" name="sendquote" formControlName="email" placeholder="your@email.com" class="send-quote-email">
                     </div>
                     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 send-quote-btn-con">
                        <button type="button"(click)="SendRequest()" class="send-quote-btn">Send</button>
                     </div>
                  </div>
                </form>
             </div> -->
          </div>
       </div>
    </div>
 </section>
 <section>


<!-- <section>
    <div class="modal-bg modal" id="ouibounce-modal" #modal [class.hide-modal]="MenuStateValues.getIsOpen()">
        <div class="donot-leave-popup">
            <button class="modal-close-btn" (click)="MenuStateValues.open()">&#10005;</button>
            <div class="d-flex flex-wrap donot-leave-popup-container">
               <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                  <div class="exit-popup-title">
                     <h1>Wait!</h1>
                     <p>Don't leave empty handed</p>
                     <p>We can email you a copy of the quotes.</p>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 popup-health-image">
                  <img src="assets/images/health-icon.svg">
               </div>
            </div>
            <div class="give-email-container col-xl-12 col-12 px-0 mt-5">
               <form [formGroup]="sendQuote" [class.formSubmitted]="formSubmitted" (ngSubmit)="sendQuote()">
                  <div class="exit-popup-input-con d-flex flex-wrap">
                     <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 sendquote-input-container">
                        <input type="text" name="name" formControlName="full_name" placeholder="Your Name" class="send-quote-input">
                     </div>
                     <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 sendquote-input-container">
                        <input type="email" name="sendquote" formControlName="email" class="give-email-input send-quote-email" placeholder="your@email.com">
                     </div>
                     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                        <button type="button" (click)="SendRequest()" class="give-email-btn">Send Quote</button>
                     </div>
                  </div>
               </form>
            </div>
        </div>
    </div>
</section> -->

<div class="loader-container" *ngIf="loading">
   <div class="loader"></div>
</div>

<!-- <section>
    <div *ngIf="active_popup">
       <div class="popup-modal-container" [class.hide-modal]="MenuStateValues.getIsOpen()">
          <div class="popup-modal">
             <button class="modal-close-btn" (click)="MenuStateValues.open()" [routerLink]="['/thank-you']">&#10005;</button>
             <div class="success-apply-text">
                <h4>Thanks for your request.</h4>
                <p>Please check your inbox for the quotes.</p>
             </div>
          </div>
       </div>
    </div>
</section> -->
 