<div class="modal_qualify_header 
  modal-header border-0 px-4">
  <h3 class="health-info m-0">Do you have any of these retirement plan?</h3>

  <button class="btn btn__close 
    close-btn-qualify-modal p-1 m-0" 
    (click)="bsModalRef.hide()">
    <ion-icon name="close-outline"></ion-icon>
  </button>
</div>

<div class="modal_qualify_body health-info-modal">
  <form class="form_modal_qualify" [formGroup]="Plans">
    <div class="ml-media-575 ">
      <div class="res-d-inline">
        <div class="health-info-drug">
          <label class="spa-chkbox-con modal-check-con text-capitalize">CD
            <input [checked]="Plans.value.cd_yes_no==true"
              (change)="selectInsurancePlan($event, 'cd_yes_no')" 
              type="checkbox" 
              value="1" 
              name="cd_yes_no"
              formControlName="cd_yes_no">
            <span class="spa-ccheckmark"></span>
          </label>
        </div>
        
        <div class="health-info-drug">
          <label class="spa-chkbox-con modal-check-con text-capitalize">401K
            <input [checked]="Plans.value['401k_yes_no']==true"
              (change)="selectInsurancePlan($event, '401k_yes_no')" 
              type="checkbox" 
              value="1" 
              name="401k_yes_no"
              formControlName="401k_yes_no">
            <span class="spa-ccheckmark"></span>
          </label>
        </div>

        <div class="health-info-drug">
          <label class="spa-chkbox-con modal-check-con text-capitalize">SEP
            <input [checked]="Plans.value.sep_yes_no==true"
              (change)="selectInsurancePlan($event, 'alzheimer_disease')" 
              type="checkbox" 
              value="1" 
              name="sep_yes_no"
              formControlName="sep_yes_no">
            <span class="spa-ccheckmark"></span>
          </label>
        </div>
      </div>

      <div class="res-d-inline">  
        <div class="health-info-drug">
          <label class="spa-chkbox-con modal-check-con text-capitalize">brokerage account
            <input [checked]="Plans.value.brokerage_account_yes_no==true"
              (change)="selectInsurancePlan($event, 'depression')" 
              type="checkbox" 
              value="1" 
              name="brokerage_account_yes_no"
              formControlName="brokerage_account_yes_no">
            <span class="spa-ccheckmark"></span>
          </label>
        </div>

        <div class="health-info-drug">
          <label class="spa-chkbox-con modal-check-con text-capitalize">municipal bonds
            <input [checked]="Plans.value.municipal_bonds_yes_no==true"
              (change)="selectInsurancePlan($event, 'municipal_bonds_yes_no')" 
              type="checkbox" 
              value="1" 
              name="municipal_bonds_yes_no"
              formControlName="municipal_bonds_yes_no">
            <span class="spa-ccheckmark"></span>
          </label>
        </div>

        <div class="health-info-drug">
          <label class="spa-chkbox-con modal-check-con text-capitalize">traditional IRA
            <input [checked]="Plans.value.traditional_ira_yes_no==true"
            (change)="selectInsurancePlan($event, 'traditional_ira_yes_no')"
            type="checkbox" 
            value="1" 
            name="traditional_ira_yes_no"
            formControlName="traditional_ira_yes_no">
            <span class="spa-ccheckmark"></span>
          </label>
        </div>
      </div>

      <div class="res-d-inline">
        <div class="health-info-drug">
          <label class="spa-chkbox-con modal-check-con text-capitalize">roth IRA
            <input [checked]="Plans.value.roth_ira_yes_no==true"
              (change)="selectInsurancePlan($event, 'roth_ira_yes_no')" 
              type="checkbox" 
              value="1" 
              name="roth_ira_yes_no"
              formControlName="roth_ira_yes_no">
            <span class="spa-ccheckmark"></span>
          </label>
        </div>

        <div class="health-info-drug">
          <label class="spa-chkbox-con modal-check-con text-capitalize">fixed annuities
            <input [checked]="Plans.value.fixed_annuities_yes_no==true"
              (change)="selectInsurancePlan($event, 'fixed_annuities_yes_no')" 
              type="checkbox" 
              value="1" 
              name="fixed_annuities_yes_no"
              formControlName="fixed_annuities_yes_no">
            <span class="spa-ccheckmark"></span>
          </label>
        </div>

        <div class="health-info-drug">
          <label class="spa-chkbox-con modal-check-con text-capitalize">variable annuities
            <input [checked]="Plans.value.variable_annuities_yes_no==true"
              (change)="selectInsurancePlan($event, 'variable_annuities_yes_no')" 
              type="checkbox" 
              value="1" 
              name="variable_annuities_yes_no"
              formControlName="variable_annuities_yes_no">
            <span class="spa-ccheckmark"></span>
          </label>
        </div>
      </div>

      <div class="res-d-inline">
        <div class="health-info-drug">
          <label class="spa-chkbox-con modal-check-con text-capitalize">saving account
            <input [checked]="Plans.value.saving_account_yes_no==true"
              (change)="selectInsurancePlan($event, 'saving_account_yes_no')" 
              type="checkbox" 
              value="1" 
              name="saving_account_yes_no"
              formControlName="saving_account_yes_no">
            <span class="spa-ccheckmark"></span>
          </label>
        </div>

          <div class="health-info-drug">
            <label class="spa-chkbox-con modal-check-con text-capitalize">Pension
              <input [checked]="Plans.value.saving_account_yes_no==true"
                (change)="selectInsurancePlan($event, 'pension_yes_no')" 
                type="checkbox" 
                value="1" 
                name="pension_yes_no"
                formControlName="pension_yes_no">
              <span class="spa-ccheckmark"></span>
            </label>
          </div>

        <div class="health-info-drug">
          <label class="spa-chkbox-con">None of the above
            <input [checked]="checkInsuranceExists('none')" 
            (change)="selectInsurancePlan($event, 'none')"
            type="checkbox" 
            value="1" 
            name="none"
            formControlName="none">
            <span class="spa-ccheckmark"></span>
          </label>
        </div>
      </div>
      <p class="error-msg-black" *ngIf="FormErrors == true">*Please select an
        option.</p>
    </div>

    <div class="spa-form-submit-modal text-right ml-media-575">
      <button class="btn spa-submit-btn-modal spa-continue-btn-modal mb-3 mt-3" (click)="continueNext()"
        type="submit">
        Finish Now
      </button>
    </div>
  </form>
</div>