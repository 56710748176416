<header>
   <nav class="navbar spa-main-nav navbar-expand-md">
       <!-- Brand -->
       <a class="navbar-brand spa-navbar-brand-img" [routerLink]="['/']">
           <img src="assets/images/insgeek.png">
       </a>
   </nav> 
</header>

<div class="page">
    <div class="container-fluid">
       <div class="ryhq4q0_e296pg">
          <div class="row">
             <div class="col-sm-3">
                <div class="side">
                     <ul class="navbar-nav">
                        <li class="nav-item animated_8s slideInDown"><a class="nav-link" [routerLink]="['/annuities']">Our annuities</a></li>
                        <li class="nav-item animated_6s slideInDown"><a class="nav-link" [routerLink]="['/compare']">How we compare</a></li>
                        <li class="nav-item animated_4s slideInDown"><a class="nav-link" [routerLink]="['/immediate-annuities']">Immediate Annuities</a></li>
                        <li class="nav-item animated_2s slideInDown"><a class="nav-link" [routerLink]="['/who-we-are']">Who we are</a></li>
                     </ul>
                </div>
             </div>
             <div class="col-sm-9 wow fadeInRight" data-wow-delay="0.2s">
                <div class="who_we" id="who">
                   <h2>Annuities for the Digital Age.</h2>
                   <p>spa is an online annuity and life insurance agency streamlined for the modern age. Our products are simple, intuitive, 
                      and backed by smart technology. At the same time, they’re designed and optimized for long-term performance. Because slow and 
                      steady is what wins the race. And we’re empowering people to win on their own terms.
                   </p>
                   <p>We’re a member of Group 1001. We currently have combined assets under management of approximately $36 billion and continue to 
                      look for opportunities to help investors grow and protect their savings.
                   </p>
                   <p>spa annuities are issued by Guggenheim Life and Annuity Company (d/b/a Guggenheim Life and Annuity Insurance Company 
                      in California; NAIC#83607).
                   </p>
                   <br>
                   <h2>Join Our Team.</h2>
                   <p>Like all companies in the Group 1001 ecosystem, we pride ourselves on our can-do culture. We've cut out bureaucracy, 
                      unnecessary silos and other types of institutional barriers to execution you'll find in more traditional companies. If you're 
                      hands-on and hungry to make a real difference in people's lives, then you might be interested to join our team. spa 
                      empowers all our employees to do their best work.
                   </p>
                   <p>We're looking for talented marketers, product managers, and developers. If interested, contact us <a href="#">here.</a></p>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>